export default {
  route: {
    dashboard: 'ホームページ',
    page401: '401',
    page404: '404'
  },
  navbar: {
    logOut: 'ログアウト',
    changePassword: 'パスワード変更',
    changeEmail: 'メール変更',
    dashboard: 'ダッシュボード',
    train: 'トレーニング',
    task: 'タスク',
    news: 'メッセージ',
    usernames: 'ユーザー名',
    mobile: '電話番号',
    mail: 'メールアドレス',
    name:'名前',
    oldPassword: '旧パスワード',
    newPassword: '新パスワード',
    confirmPassword: '新パスワード確認',
    oldEmail: '旧メール',
    newEmail: '新メール',
    confirmEmail: '新メール確認',
  },
  login: {
    title: 'システムログイン',
    logIn: 'ログイン',
    empname: '名前を入力してください',
    username: 'メールを入力',
    password: 'パスワードを入力',
    verificationCode: '確認コードを入力',
    reName: 'ユーザー名記憶',
    ldapLogIn: 'LDAPログイン',
    verificationCodeError: "誤った確認コード",
    verificationMobileError: "不正な電話番号形式",
    verificationMailError:"メールボックスのフォーマットが正しくありません",
    formError: "解答時間がタイムアウトしました，フォームを完全に入力",
  },
  table: {
    cancel: 'キャンセル',
    confirm: '確認'
  },
  common: {
    cn: '中国語',
    jp: '日本語',
    en: '英語',
    pleaseSelect: '選択してください',
    pleaseInput: '入力してください',
    pleaseInputText: '内容を入力してください。',
    actionsConfirm: 'メール変更をキャンセルしますか？現在のメールが仕事用であることを確認してください。',
    formNotNullNotice: 'この項目は必須です。',
    canceledAction: '操作キャンセル',
    actionsNotice: '操作ヒント',
    restartMbti: '答案をやり直すかどうか？',
  },
  home: {
    trainingProgress: 'トレーニング進捗',
    complete: "完了",
    train: 'トレーニングセッション',
    total: '合計',
    term: '項目数',
    continueLearning: '学習を続ける',
    message: '未読メッセージ',
    more: 'もっと見る',
    unread: '未読',
    myTraining: 'マイトレーニング',
    trainingName: 'トレーニング名',
    status: '状態',
    deadline: '締切',
    startTrain: 'トレーニング開始',
    myTask: 'マイタスク',
    taskName: 'タスク名',
    type: 'タイプ',
    startTime: '開始日',
    endTime: '終了日',
    startTask: 'テストを開始',
  },
  train: {
    allTrain: '全トレーニング',
    all: '全て',
    activated: 'アクティブ',
    closed: '終了',
    search: '検索クエリ入力',
    courseIntroduction: 'コース詳細',
    currentProgress: '進捗状況',
    catalogue: 'カタログ',
    next: '次のセッション',
    pre1: '前のセッション',
    next1: '次のセッション',
    describe: '章の詳細',
    pleaseFinishPre: '前の章を先に完了してください',
    check: 'チェック',
    downloadCertificate: '証明書ダウンロード',
    startTraining: 'トレーニング開始',
    Congratulations: 'おめでとうございます！',
    continue: '続ける',
    rePlay: '再生',
    redo: 'やり直し',
    retest: '再テスト',
    Retrospective: 'トレーニングレビュー',
    training: 'トレーニング完了',
    accomplish: '{title}トレーニングを{durationTimeDesc}で完了しました。',
    questionTotal: '合計{totalNum}問。',
    mustAnswerAllQuestions: '全問に回答してください',
    explain: '合計{totalNum}問提出し、{rightNum}問正解。トレーニングを続けるには最低{minRightNum}問正解が必要です。',
    explainAll: '合計{totalNum}問提出し、{rightNum}問正解。',
    explainTo: '合計{totalNum}問提出し、{rightNum}問正解。やり直しと続行の選択肢があります。'
  },
  task: {
    allTask: '全タスク',
    answer:'正解',
    taskName: 'タスク名',
    status: '状態',
    type: 'タイプ',
    startTime: '開始時間',
    endTime: '終了時間',
    operation: '操作',
    completeTask: 'タスクの開始',
    see: '閲覧',
    submited:'送信済み',
    enterTaskName: 'タスク名を入力',
    searchType: 'タスクタイプを選択',
    searchStatus: '状態を入力',
    search: '検索',
    questionnaire: 'アンケート',
    knowledge: '知識競争',
    test: '試験',
    choose: '選択してください',
    submit: '提出',
    close: '終了',
    resubmit: '再提出',
    unStart: '未開始',
    preIndex:'前の問題',
    requiredMsg:'テーマを完成してください{index}',
  },
  news: {
    allNews: '全メッセージ',
    all: '全て',
    unread: '未読',
    read: '既読',
    sender: '送信者',
    messageSubject: 'メッセージ題目',
    sendingTime: '送信時間',
    remark: '全て既読にする',
    delete: '削除',
    return: 'リストへ戻る'
  }
}
