import Vue from 'vue'
import Router from 'vue-router'

// import NProgress from 'nprogress' // progress bar
// import 'nprogress/nprogress.css'// progress bar style

// import Element from "element-ui";
Vue.use(Router)
// 引入组件

//重写push和replace方法
let originPush=Router.prototype.push;
let originReplace=Router.prototype.replace;
Router.prototype.push=function(location,resolve,reject){
    if(resolve&&reject){
        originPush.call(this,location,resolve,reject);
    }else{
        originPush.call(this,location,()=>{},()=>{})
    }
}
Router.prototype.replace=function(location,resolve,reject){
    if(resolve&&reject){
        originReplace.call(this,location,resolve,reject);
    }else{
        originReplace.call(this,location,()=>{},()=>{})
    }
}
 const constantRouterMap =[
  {
    path: '/',
    redirect: '/login'
  },
  { // 登录页
    path: '/login',
    component: () => import('../views/login/index.vue'),
    meta: { title:"登录", FoodShow: true }
  },
  {//主页面
    path: "/main",
    redirect: "/home",
    name: "main",
  },
  //消息页
      {
        path: "/news",
        component: () => import("../views/main/news/news.vue"),
        name: "/news",
        meta: { title: "消息" }
      },
      {
        path: "/newsDetail",
        component: () => import("../views/main/news/newsDetail.vue"),
        name: "/newsDetail",
        meta: { title: "消息详情" }
      },
//任务页
      {
        path: "/task",
        component: () => import("../views/main/task/task.vue"),
        name: "task",
        meta: { title: "任务" }
      },
      {
        path: "/beginTask",
        component: () => import("../views/main/task/beginTask"),
        name: "beginTask",
        meta: { title: "开始任务" }
      },
      {
        path: "/noSessionBeginTask",
        component: () => import("../views/main/task/noSessionBeginTask"),
        name: "noSessionBeginTask",
        meta: { title: "MBTI不登录测验" }
      },
     {
         path: "/enfjDetailNoSession",
         component: () => import("../views/main/task/enfjDetailNoSession"),
         name: "enfjDetailNoSession",
         meta: { title: "ENFJ性格" }
     },
     {
         path: "/enfpDetailNoSession",
         component: () => import("../views/main/task/enfpDetailNoSession"),
         name: "enfpDetailNoSession",
         meta: { title: "ENFP性格" }
     },
     {
         path: "/entjDetailNoSession",
         component: () => import("../views/main/task/entjDetailNoSession"),
         name: "entjDetailNoSession",
         meta: { title: "ENTJ性格" }
     },
     {
         path: "/entpDetailNoSession",
         component: () => import("../views/main/task/entpDetailNoSession"),
         name: "entpDetailNoSession",
         meta: { title: "ENTP性格" }
     },
     {
         path: "/esfjDetailNoSession",
         component: () => import("../views/main/task/esfjDetailNoSession"),
         name: "esfjDetailNoSession",
         meta: { title: "ESFJ性格" }
     },
     {
         path: "/esfpDetailNoSession",
         component: () => import("../views/main/task/esfpDetailNoSession"),
         name: "esfpDetailNoSession",
         meta: { title: "ESFP性格" }
     },
     {
         path: "/estjDetailNoSession",
         component: () => import("../views/main/task/estjDetailNoSession"),
         name: "estjDetailNoSession",
         meta: { title: "ESTJ性格" }
     },
     {
         path: "/estpDetailNoSession",
         component: () => import("../views/main/task/estpDetailNoSession"),
         name: "estpDetailNoSession",
         meta: { title: "ESTP性格" }
     },
     {
         path: "/infjDetailNoSession",
         component: () => import("../views/main/task/infjDetailNoSession"),
         name: "infjDetailNoSession",
         meta: { title: "INFJ性格" }
     },
     {
         path: "/infpDetailNoSession",
         component: () => import("../views/main/task/infpDetailNoSession"),
         name: "infpDetailNoSession",
         meta: { title: "INFP性格" }
     },
     {
         path: "/intjDetailNoSession",
         component: () => import("../views/main/task/intjDetailNoSession"),
         name: "intjDetailNoSession",
         meta: { title: "INTJ性格" }
     },
     {
         path: "/intpDetailNoSession",
         component: () => import("../views/main/task/intpDetailNoSession"),
         name: "intpDetailNoSession",
         meta: { title: "INTP性格" }
     },
     {
         path: "/isfjDetailNoSession",
         component: () => import("../views/main/task/isfjDetailNoSession"),
         name: "isfjDetailNoSession",
         meta: { title: "isfj性格" }
     },
     {
         path: "/isfpDetailNoSession",
         component: () => import("../views/main/task/isfpDetailNoSession"),
         name: "isfpDetailNoSession",
         meta: { title: "isfp性格" }
     },
     {
         path: "/istjDetailNoSession",
         component: () => import("../views/main/task/istjDetailNoSession"),
         name: "istjDetailNoSession",
         meta: { title: "ISTJ性格" }
     },
     {
         path: "/istpDetailNoSession",
         component: () => import("../views/main/task/istpDetailNoSession"),
         name: "istpDetailNoSession",
         meta: { title: "ISTP性格" }
     },
      {
        path: "/enfjDetail",
        component: () => import("../views/main/task/enfjDetail"),
        name: "enfjDetail",
        meta: { title: "ENFJ性格" }
      },
      {
        path: "/enfpDetail",
        component: () => import("../views/main/task/enfpDetail"),
        name: "enfpDetail",
        meta: { title: "ENFP性格" }
      },
      {
        path: "/entjDetail",
        component: () => import("../views/main/task/entjDetail"),
        name: "entjDetail",
        meta: { title: "ENTJ性格" }
      },
      {
        path: "/entpDetail",
        component: () => import("../views/main/task/entpDetail"),
        name: "entpDetail",
        meta: { title: "ENTP性格" }
      },
      {
        path: "/esfjDetail",
        component: () => import("../views/main/task/esfjDetail"),
        name: "esfjDetail",
        meta: { title: "ESFJ性格" }
      },
      {
        path: "/esfpDetail",
        component: () => import("../views/main/task/esfpDetail"),
        name: "esfpDetail",
        meta: { title: "ESFP性格" }
      },
      {
        path: "/estjDetail",
        component: () => import("../views/main/task/estjDetail"),
        name: "estjDetail",
        meta: { title: "ESTJ性格" }
      },
      {
        path: "/estpDetail",
        component: () => import("../views/main/task/estpDetail"),
        name: "estpDetail",
        meta: { title: "ESTP性格" }
      },
      {
        path: "/infjDetail",
        component: () => import("../views/main/task/infjDetail"),
        name: "infjDetail",
        meta: { title: "INFJ性格" }
      },
      {
        path: "/infpDetail",
        component: () => import("../views/main/task/infpDetail"),
        name: "infpDetail",
        meta: { title: "INFP性格" }
      },
      {
        path: "/intjDetail",
        component: () => import("../views/main/task/intjDetail"),
        name: "intjDetail",
        meta: { title: "INTJ性格" }
      },
      {
        path: "/intpDetail",
        component: () => import("../views/main/task/intpDetail"),
        name: "intpDetail",
        meta: { title: "INTP性格" }
      },
      {
        path: "/isfjDetail",
        component: () => import("../views/main/task/isfjDetail"),
        name: "isfjDetail",
        meta: { title: "isfj性格" }
      },
      {
        path: "/isfpDetail",
        component: () => import("../views/main/task/isfpDetail"),
        name: "isfpDetail",
        meta: { title: "isfp性格" }
      },
      {
        path: "/istjDetail",
        component: () => import("../views/main/task/istjDetail"),
        name: "istjDetail",
        meta: { title: "ISTJ性格" }
      },
      {
        path: "/istpDetail",
        component: () => import("../views/main/task/istpDetail"),
        name: "istpDetail",
        meta: { title: "ISTP性格" }
      },
//培训页
      {
        path: "/train",
        component: () => import("../views/main/train/list"),
        name: "/train",
        meta: { title: "培训" }
      },
      {
        path: "/trainDetail",
        component: () => import("../views/main/train/train_detail.vue"),
        name: "/trainDetail",
        meta: { title: "培训详情" }
      },
      {
        path: "/trainContent",
        component: () => import("../views/main/train/train_content.vue"),
        name: "/trainContent",
        meta: { title: "培训内容" }
      },
      {
        path: "/videoTrain",
        component: () => import("../views/main/train/video_train.vue"),
        name: "/videoTrain",
        meta: { title: "视频培训" }
      },
      {
        path: "/articleTrain",
        component: () => import("../views/main/train/articleTrain.vue"),
        name: "/articleTrain",
        meta: { title: "文章培训" }
      },
      {
        path: "/cardListTrain",
        component: () => import("../views/main/train/cardList_train.vue"),
        name: "/cardListTrain",
        meta: { title: "卡片组培训" }
      },
      {
        path: "/testTrain",
        component: () => import("../views/main/train/testTrain.vue"),
        name: "/testTrain",
        meta: { title: "试题培训" }
      },
      {
        path: "/home",
        component: () => import("../views/main/home"),
        name: "home",
        meta: { title: "首页" }
      },

]

const router = new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
});
// router.beforeEach((to,from,next) =>{
//     let token = localStorage.getItem('token');//获取token
//     console.log(to.path);
//   if (token || to.path === '/login') {//有token或者在login页面下通行
//     NProgress.start() // start progress bar
//     next();
//   } else {
//     // alert('无权访问');
//     Element.Message({
//       message:'请登录',
//           type:'error'
//     })
//     next('/login');
//   }
// })
// router.afterEach(() => {
//   NProgress.done() // finish progress bar
// })

// 获取原型对象push函数
// const originalPush = router.prototype.push

// // 获取原型对象replace函数
// const originalReplace = router.prototype.replace

// // 修改原型对象中的push函数
// router.prototype.push = function push(location){
// return originalPush.call(this , location).catch(err=>err)
// }

// // 修改原型对象中的replace函数
// router.prototype.replace = function replace(location){
// return originalReplace.call(this , location).catch(err=>err)
// }

export default router
