/* 引入encryptlong实现数据RSA加密 */
import Encrypt from 'encryptlong' // encryptlong是基于jsencrypt扩展的长文本分段加解密功能。

export default {
  /* 加密 */
  encrypt(data, PUBLIC_KEY) {
    // const PUBLIC_KEY = window.securityCode;
    var encryptor = new Encrypt();
    encryptor.setPublicKey(PUBLIC_KEY)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    const result = encryptor.encryptLong(data);
    return result
  }
}
